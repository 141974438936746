import React, {useState} from 'react';
import ReactDOM from "react-dom";
import DynamicMultiRow from "../DynamicMultiRow";
import Table from "../Table";
import DeleteButton from "./DeleteButton";
import AddPoeButton from "./AddPoeButton";
import EditButton from "./EditButton";

const PropertyEdit = props => {
    const {poes, propertyPoes, ...rest} = props;
    const [assocPoes, setAssocPoes] = useState(propertyPoes.map(proppoe => proppoe.id));
    return (
        <div>
            <h3 className="header3--blue">New POEs to add</h3>
            <div>
                <DynamicMultiRow {...rest}/>
            </div>
            <h3 className="header3--blue">Modify existing POEs</h3>
            <div>
                <Table
                    name={'existing-poe-mod'}
                    data={poes}
                    headers={[
                        {key: 'name', title: 'POE name'},
                        {key: 'updated_at', title: 'Date updated'}
                    ]}
                    actionElements={[
                        {'el': AddPoeButton, 'header': "Add/Remove POE to Property", 'props': {'callback': setAssocPoes, assocPoes}},
                        {'el': EditButton, 'header': "Open POE edit page", 'props': {'url': '/admin/users/poe', 'isBlankTarget': true}},
                    ]}
                />
            </div>
        </div>
    );
};

if (document.querySelector('[data-react-component="property-edit"]') && poeEditData) {
    ReactDOM.render(<PropertyEdit  {...poeEditData}/>, document.querySelector('[data-react-component="property-edit"]'));
}

export default PropertyEdit;

import React from 'react';
import {func, string} from "prop-types";

const SearchBox = props => {
    const {
        searchValue,
        setSearchValue,
        ...rest
    } = props;

    return (
        <div {...rest}>
            <input onChange={(ev) => setSearchValue(ev.target.value)} value={searchValue ? searchValue : ''} type={"text"} className={"form__input form__input--single no-print"}
                   placeholder="Search..."/>
            <img src={"/images/delete-grey.png"} alt={"delete button"} className={"search__delete-icon no-print"}
                 onClick={() => {
                     setSearchValue('')
                 }}/>
        </div>
    );
};

SearchBox.propTypes = {
    searchValue: string,
    setSearchValue: func
}

export default SearchBox;

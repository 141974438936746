import React, {useEffect, useState} from 'react';
import ReactDOM from "react-dom";
import SearchBox from "../SearchBox";
import {array,  string} from "prop-types";
import ImportButton from "../ImportButton";
import Error from "../Error";
import Table from "../Table";
import ColumnSelector from "../ColumnSelector";
import PrintButton from "../PrintButton";
import {useSearch} from "../../hooks/useSearch";
import {getMetaTag} from "../../util/getMetaTags";
import {useMultiDelete} from "../../hooks/useMultiDelete";
import {usePager} from "../../hooks/usePager";
import {useUpdateData} from "../../hooks/useUpdateData";
import DeleteButton from "./DeleteButton";
import EditButton from "./EditButton";
import DeleteConfirm from "./DeleteConfirm";
import {toast, ToastContainer} from "react-toastify";

const Distributions = props => {
    const {tableData, tableHeaders, ...rest} = props;
    const [errors, setErrors] = useState([]);
    const [isLoading, setLoading] = useState(!tableData);
    const [data, setData] = useState(tableData);
    const [headers, setHeaders] = useState(tableHeaders);
    const [searchValue, setSearchValue] = useState(null);
    const [limit, setLimit] = useState(15);
    const [deleteIds, setDeleteIds] = useState([]);

    const {filterData} = useSearch();
    const csrfToken = getMetaTag("csrf-token");
    const {deleteFn} = useMultiDelete({
        endpoint: "/admin/distributions",
        csrfToken: csrfToken?.[0]?.content,
        deleteIds,
        setLoading,
        setData,
        setErrors,
        setDeleteIds
    });
    const {updateData} = useUpdateData({
        endpoint: "/admin/distributions/json",
        setLoading,
        setData,
        setErrors
    });
    const {curPage, maxPage, prevPage,nextPage, startNum, endNum} = usePager({limit, dataLength: data.length});

    useEffect(() => {
        setData(filterData({searchValue, data: tableData}));
    }, [searchValue]);

    useEffect(async () => {
        if (!data) {
            await updateData();
        }
    }, []);

    const setHeadersFn = headersList => {
        setHeaders(tableHeaders.filter(tableHeader => headersList.includes(tableHeader.key)));
    };

    const toastedDelete = async () => {
        await deleteFn();
        if (errors && errors.length > 0) {
            errors.forEach(error => {
                toast.error(error);
            });
            return;
        }
        toast.success(`Properties IDs ${deleteIds} deleted`);
    };

    const editHeader = <div className={'form__icon form__icon--button-edit'}/>;
    const deleteHeader = <div onClick={() => {setDeleteIds(data.map(dat => dat.id))}} className={'form__icon form__icon--button-delete'}/>;
    return (
        <div className={"distributions-react"}>
            <ToastContainer autoClose={10000} theme={"light"}/>
            {isLoading ? (<div className={"loading--background"}>
                Loading
                <div className={"loading loading--inner"}></div>
            </div>) : null}
            <Error errors={errors}/>
            <h2 className="header2 header2--blue text-align-center">Distributions</h2>
            <a href={"/admin/distributions/create"} className="form__button--add no-print button--dkblue-white">
                <img src="/images/add.png" alt="add button" className={"form__icon form__icon--add"}/>
                <h4 className="header4--blue">Add New Distribution</h4>
            </a>

            <span>
                <SearchBox className={"searchBox form--inline"} searchValue={searchValue} setSearchValue={setSearchValue}/>
                <span>
                    <label htmlFor={'perPage'}>Show per page:</label>
                    <select name={'perPage'} className={'form__perpage'} onChange={(ev) => {setLimit(parseInt(ev.target.value) ? parseInt(ev.target.value) : 15)}}>
                        <option value={"15"}>15</option>
                        <option value={"25"}>25</option>
                        <option value={"50"}>50</option>
                        <option value={"10000"}>MAX</option>
                    </select>
                </span>
                <ColumnSelector className={"columnSelector form--inline"} name={"distributions"} headers={tableHeaders} setActiveCols={setHeadersFn} />
            </span>

            <Table
                className={"table--wrap"}
                name={"distributions"}
                data={data}
                headers={headers}
                curPage={curPage}
                startNum={startNum}
                endNum={endNum}
                actionElements={[
                    {'el': DeleteButton, 'header': deleteHeader, 'props': {'callback': setDeleteIds, deleteIds}},
                    {'el': EditButton, 'header': editHeader, 'props': {url: '/admin/distributions'}},
                ]}
            />

            <span>
                <DeleteConfirm onClick={toastedDelete}>
                    There are <span>{deleteIds.length}</span> rows selected
                </DeleteConfirm>
                <a className={"button button--dkblue-white form__button--export"}
                   href={"/admin/distributions/template"}>Export Template</a>
                <a className={"button button--dkblue-white form__button--export"} href={"/admin/distributions/export"}>Export Data</a>
                <ImportButton endpoint={"admin/distributions"} setData={setData} setLoading={setLoading}
                              setErrors={setErrors}/>

                <button className={"button--blue-opaque"} onClick={prevPage}>&lt;</button>
                <span className={"table__page"}>{curPage + 1} / {maxPage + 1}</span>
                <button className={"button--blue-opaque"} onClick={nextPage}>&gt;</button>
            </span>

        </div>
    );
};

Distributions.propTypes = {
    tableHeaders: array,
    tableName: string,
    tableData: array
}

export default Distributions;

const init = () => {
    if (document.querySelector('[data-react-component="Distributions"]') && tableReactData) {
        ReactDOM.render(<Distributions {...tableReactData} />, document.querySelector('[data-react-component="Distributions"]'));
    }
}
init();

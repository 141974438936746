import React from 'react';
import {arrayOf, func, number} from "prop-types";

/**
 * Use the callback to push our ID to a number list State
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const DeleteButton = props => {
    const {callback: setDeleteIds, deleteIds, data, children, ...rest} = props;
    return (
        <input {...rest}
               checked={deleteIds?.some(deleteId => deleteId === data.id)}
               name={"delete" + data.id}
               type={"checkbox"}
               onChange={(ev) => setDeleteIds((oldIds) => {
                   if (oldIds.includes(data.id)) {
                       return oldIds.filter(oldId => oldId !== data.id)
                   }
                   return [...oldIds, data.id]
               })}
        />
    );
};

DeleteButton.propTypes = {
    callback: func,
    deleteIds: arrayOf(number)
}

export default DeleteButton;

import React, {useEffect, useState} from "react";
import {array, arrayOf, number, string, shape, bool, elementType, object} from "prop-types";
import {useHeaderSort} from "../hooks/useHeaderSort";
import TableCell from "./TableCell";
import {isNull} from "lodash/lang";

const Table = props => {
    const {data: propData, headers, curPage, startNum,endNum, name, actionElements, children, ...rest} = props;
    const [data, setData] = useState(propData);

    const [visibleColumns, setVisibleColumns] = useState([]);
    const {sortHeaders} = useHeaderSort();

    const formatPhoneNumber = (phoneNumberString) => {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            var intlCode = (match[1] ? '+1 ' : '');
            return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
        }
        return null;
    };

    const getTransforms = (key, value) => {
        const transforms = headers.find(header => header.key === key)?.transforms;
        if (!transforms || transforms.length < 1) {
            return value;
        }
        return transforms.reduce((accumulator, currentValue) => {
            if (isNull(accumulator) || accumulator === undefined) {
                return "";
            }
            if (currentValue === "percent" && (typeof accumulator === "number" || !isNaN(parseFloat(accumulator)))) {
                return "%" + (accumulator * 100).toFixed(6)
            }
            if (currentValue === "capitalize") {
                return accumulator.toString().charAt(0).toUpperCase() + accumulator.toString().slice(1)
            }
            if (currentValue === "phone") {
                return formatPhoneNumber(currentValue);
            }
            if (currentValue === "dateFull") { //todo date parsing/display
                return accumulator;
            }
            if (currentValue === "boolean") {
                return accumulator ? "Yes" : "No";
            }
            if (currentValue === "currency") {
                return "$" + accumulator;
            }
            return accumulator ?? "";
        }, value);
    };

    useEffect(() => {
        setData(propData);
    }, [propData]);
    useEffect(() => {
        setVisibleColumns(headers.map(header => header.key));
    }, [headers]);

    const tableHeaders = headers.map(header => {
        return (
            <th className="table__headers" id={header.key} key={header.key}
                onClick={() => setData(sortHeaders(header.key,data))}>
                {header.title}
            </th>
        );
    });

    const actionElementHeaders = actionElements?.map(({el: Element, header, props}, idx) => {
        return (
            <th className="table__headers" id={'actionElHeader' + idx} key={'actionElHeader' + idx}>{header}</th>
        );
    });

    const tableRows = data.slice(startNum, endNum).map((dataItem) => {
        return (
            <tr key={dataItem.id} className="table__row">
                {visibleColumns.map((visibleColumn, indexColumn) => {
                    return (
                        <td key={'column' + indexColumn} onClick={() => {}} className={"table__data"}>
                            <TableCell data={getTransforms(visibleColumn, dataItem[visibleColumn])} type={visibleColumn}/>
                        </td>
                    );
                })}
                {actionElements?.map(({el: Element, props}, idx) => {
                    return (
                        <td key={'extraColumn' + idx} className={"table__data"}>
                            <Element {...props} data={dataItem}/>
                        </td>
                    );
                })}
            </tr>
        );
    });


    return (
        <div {...rest}>
            <table className="table print">
                <thead className="table__head" id="table-headers">
                <tr className="table__row">
                    {tableHeaders}
                    {actionElementHeaders}
                </tr>
                </thead>
                <tbody id="table-data">
                {tableRows}
                </tbody>
            </table>
            {children}
        </div>
    );
};

Table.propTypes = {
    data: array,
    headers: arrayOf(shape({
        title: string,
        key: string,
        transforms: array
    })),
    actionElements: arrayOf(shape({
        el: elementType,
        props: object
    })),
    curPage: number,
    startNum: number,
    endNum: number,
    name: string,
}

Table.defaultProps = {
    curPage: 0,
    startNum: 0,
    endNum: 1000
}

export default Table;

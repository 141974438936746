import React from 'react';

/**
 * Get meta tag data from the DOM
 * @returns {{name: string, content: string}[]}
 */
const getMetaTags = () => {
    return Array.from(document.getElementsByTagName('meta')).map((tag) => {
        if (!tag.name || !tag.content) {
            return;
        }
        return {
            name: tag.name,
            content: tag.content
        }
    });
}

export const getMetaTag = (tagSearch) => {
    return getMetaTags().filter(tag => {
        return tag.name === tagSearch
    });
};

export default getMetaTags;

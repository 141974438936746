import React, {useRef} from 'react';
import Error from "./Error";
import {getMetaTag} from "../util/getMetaTags";
import {func, string} from "prop-types";

const ImportButton = props => {
    const {setLoading, setErrors, setData, endpoint} = props;

    const inputFileRef = useRef();
    const csrfToken = getMetaTag("csrf-token");

    const importFn = (event) => {
        setLoading(true);
        event.preventDefault();

        const file = inputFileRef?.current?.files?.[0];
        if (!csrfToken || !csrfToken.length) {
            console.error('no csrf token');
            return;
        }
        if (!file) {
            console.error("import file could not be read");
            return;
        }

        const url = new URL(window.location.origin + '/' + endpoint + "/import");
        const formData = new FormData();
        formData.set("_token", csrfToken[0].content);
        formData.set("file", file);
        const headers = new Headers({
            "X-Requested-With": "XMLHttpRequest",
            "X-CSRF-TOKEN": csrfToken[0].content
        });
        const request = new Request(url, {
            method: "POST",
            headers: headers,
            cache: "default",
            body: formData
        });
        fetch(request)
            .then(res => res.json())
            .then(result => {
                if (!result.error) {
                    setData(result.data);
                    setErrors([result.message]);
                } else {
                    setErrors([result.message, ...result?.error_data]);
                }
                setLoading(false);
            }).catch((e) => {
                setErrors([e.message]);
                setLoading(false);
        });
    };

    return (
        <form action="" method={'POST'} className={"form__button--export-form"}>
            <label htmlFor="csv_upload" className="button button--dkblue-white form__button--export">
                Import Data
                <input type="file" name="csv_upload" id="csv_upload" ref={inputFileRef} onChange={importFn}
                       className={"form__file-upload"}/>
            </label>
        </form>
    );
};

ImportButton.propTypes = {
    setErrors: func,
    setLoading: func,
    setData: func,
    endpoint: string
};

export default ImportButton;

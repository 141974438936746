import React, {useState} from 'react';
import ReactDOM from "react-dom";

const Attributes = props => {
    const [error, setError] = useState(null);

    return (
        <>
            <h2 className="header2 header2--blue text-align-center">Investment Data Attributes</h2>
            <a href={props.endpoint + "create"} className="form__button--add no-print button--dkblue-white">
                <img src="/images/add.png" alt="add button" className={"form__icon form__icon--add"}/>
                <h4 className="header4--blue">Add New</h4>
            </a>

            <Search showExport={true} showPrint={true} showImport={true} showTemplate={true} {...props} error={error}
                    setError={setError}/>
        </>
    );
};

if (document.querySelector('[data-react-component="Attributes"]')) {
    ReactDOM.render(<Attributes {...tableReactData} />, document.querySelector('[data-react-component="Attributes"]'));
}

export default Attributes;

export const useSearch = () => {
    const filterData = ({searchValue, data}) => {
        if (!searchValue || !data) {
            return data;
        }
        return data.filter(dataRow => {
            return Object.values(dataRow).some(dataCell => dataCell?.toLowerCase?.().includes(searchValue?.toLowerCase()));
        });
    };

    return {
        filterData
    };
};

import React, {useState} from "react";
import {func} from "prop-types";

const DeleteConfirm = props => {
    const {onClick, children} = props;
    const [showConfirm, setConfirm] = useState(false);
    return (
        <>
            {showConfirm ? (
                <div className={'form__popup'}>
                    <div className={'form__popup-inner'}>
                        <div className={'form__popup-text'}>
                            {children}
                            <hr/>
                            Are you sure you want to delete the selected rows?
                        </div>
                        <div>
                            <button onClick={() => {
                                setConfirm(false);
                                onClick();
                            }} type={'button'} className={'button button--red-opaque form__button--delete'}>Delete Selected</button>
                            <button onClick={() => setConfirm(false)} type={'button'} className={'button'}>Cancel</button>
                        </div>
                    </div>
                </div>
            ) : null}
            <button className={'form--mass-delete'} onClick={() => setConfirm(true)}>Delete Selected</button>
        </>
    );
};

DeleteConfirm.propTypes = {
    onClick: func
};

export default DeleteConfirm;

import {useState} from "react";

export const usePager = ({limit, dataLength}) => {
    const [curPage, setCurPage] = useState(0);
    const maxPage = Math.floor(dataLength / limit);

    const prevPage = (ev) => {
        if (curPage <= 0) {
            return;
        }
        setCurPage(page => --page);
    };

    const nextPage = (ev) => {
        if (curPage >= maxPage) {
            return;
        }
        setCurPage(page => ++page);
    };
    return {
        curPage,
        maxPage,
        prevPage,
        nextPage,
        startNum: curPage * limit,
        endNum: curPage * limit + limit
    }
};

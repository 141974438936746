import React, {useState} from 'react';
import ReactDOM from "react-dom";
import AccordionItem from "./AccordionItem";
import DocumentLine from "./admin/Documents/DocumentLine";

//todo check this?
const Accordion = props => {
    const {propertyData, investData} = props;
    const [accordionKey, setAccordionKey] = useState(0);
    const [accordionItems, setAccordionItems] = useState([{...props, index: accordionKey, key: accordionKey}]);
    const [isExpanded, setIsExpanded] = useState(false);

    const propertyHeader = propertyData.map(property => {
        return (
            <button>
                <h3 className="header3 header3--blue text-align-center">{property["property_name"]}</h3>
            </button>
        )
    });

    return (
        <>
            <div className="property__column">
                {accordionItems.map((accordionItem) => {
                    return (
                        <>
                            {propertyHeader}
                            <AccordionItem {...accordionItem}/>
                        </>);
                })}


            </div>
        </>
    )
}

if (document.querySelector('[data-react-component="Property"]')) {
    ReactDOM.render(<Accordion {...accordionReactData} />, document.querySelector('[data-react-component="Property"]'));
}

export default Accordion;

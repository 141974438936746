import React, {useEffect, useMemo, useState} from 'react';
import {array, arrayOf, func, number, shape, string} from "prop-types";
import classNames from "classnames";

const ColumnSelector = props => {
    const {
        headers,
        name,
        setActiveCols,
        ...rest
    } = props;
    const [isVisible, setIsVisible] = useState(false);
    const activeCols = () => {
        return headers.map(col => {
            const localStorageState = localStorage.getItem(name + col.key);
            return {'key': col.key, 'active': localStorageState === 'true'};
        }).filter(col => {
            return !!col.active;
        });
    };
    useEffect(() => {
        headers.forEach(col => {
            const localStorageState = localStorage.getItem(name + col.key);
            if (localStorageState === null) {
                localStorage.setItem(name + col.key, true);
            }
        });
        setActiveCols(
            activeCols().map(activeCol => activeCol.key)
        );
    }, []);

    const updateColumns = (e) => {
        e.preventDefault();
        localStorage.setItem(name + e.target.id, e.target.checked);
        setActiveCols(
            headers.filter(col => localStorage.getItem(name + col.key) === 'true').map(header => header.key)
        );
    };
    const allColumns = headers.map(col => {
        const localStorageState = localStorage.getItem(name + col.key);
        return {'key': col.key, 'active': localStorageState === 'true'};
    });
    return (
        <div {...rest}>
            <button onClick={() => {setIsVisible(prevState => !prevState)}} className={"columnSelector__show button"}>
                Show/Hide Columns
            </button>
            <div className={classNames("columnSelector__columns", {"columnSelector__columns--visible": isVisible})}>
                <div className="form__row form__row--justify-space-between">
                    <h3 className="header3 header3--blue columnSelector__popup--header">Select Columns to Show/Hide</h3>
                    <img src="/images/delete-grey.png" alt="delete button" className={"columnSelector__popup--close"}
                         onClick={() => {
                             setIsVisible(!isVisible)
                         }}/>
                </div>
                {headers.map((column, idx) => {
                    return (<div className={"columnSelector__column"} key={idx}>
                        <label className={"columnSelector__label"} htmlFor={column.key}>{headers.find(col => col.key === column.key).title}</label>
                        <input onInput={updateColumns} defaultChecked={!allColumns.find(col=> !col.active && col.key === column.key)} type={"checkbox"} className={"columnSelector__columnInput"} id={column.key}/>
                    </div>);
                })}
            </div>
        </div>
    );
};

ColumnSelector.propTypes = {
    headers: arrayOf(shape({
        title: string,
        key: string,
        transforms: array
    })),
    setActiveCols: func,
    name: string,
}

export default ColumnSelector;

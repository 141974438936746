import React from 'react';
import {number, string} from 'prop-types';

const EditButton = props => {
    const {url, isBlankTarget = false, data, children, ...rest} = props;
    const extraProps = {
        ...(isBlankTarget && {target: "_blank"})
    };
    return (
        <a {...rest} {...extraProps} href={url + '/' + data.id + '/edit'} className={"form__icon form__icon--button form__icon--button-edit"}>
            {children}
        </a>
    );
};

EditButton.propTypes = {
    url: string
}

export default EditButton;

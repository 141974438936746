import {useState} from "react";

export const useHeaderSort = () => {
    const [sortDirection, setSortDirection] = useState('asc');
    const sortHeaders = (key, data) => {
        const newDataOrder = [...data];
        if (sortDirection === 'asc') {
            newDataOrder.sort((a, b) => {
                let dataA = a[key] !== null ? a[key] : '';
                let dataB = b[key] !== null ? b[key] : '';
                return (dataA < dataB) ? -1 : (dataA > dataB) ? 1 : 0;
            });
            setSortDirection('desc');
        } else {
            newDataOrder.sort((a, b) => {
                let dataA = a[key] !== null ? a[key] : '';
                let dataB = b[key] !== null ? b[key] : '';
                return (dataA < dataB) ? 1 : (dataA > dataB) ? -1 : 0;
            });
            setSortDirection('asc');
        }
        return newDataOrder;
    };
    return {
        sortHeaders
    };
};

import {getMetaTag} from "../../../util/getMetaTags";
import ReactDOM from "react-dom";
import React, {useRef, useState} from "react";

const Create = props => {
    const {
        users,
        properties,
        poes,
        ...rest
    } = props;
    const [addby, setAddby] = useState("user");
    const [message, setMessage] = useState(null);
    const csrfToken = getMetaTag("csrf-token");
    const formRef = useRef();
    const submit = async () => {
        const formData = new FormData(formRef.current);
        formData.set("_token", csrfToken?.[0]?.content);
        const headers = new Headers({
            "X-Requested-With": "XMLHttpRequest",
            "X-CSRF-TOKEN": csrfToken,
            "Content-Type": 'application/json'
        });
        const request = new Request("/admin/distributions", {
            method: "POST",
            headers: headers,
            cache: "default",
            body: JSON.stringify(Object.fromEntries(formData.entries()))
        });
        const response = await fetch(request);
        const data = await response.json();
        if (data?.message) {
            setMessage(data.message);
        }
        formRef.current.reset();
    };
    return (
        <form ref={formRef} {...rest} className={"form form__column"} id={"create-distribution-form"}>

            {message ?
                <ul className="msg msg__error">
                    <li>{message}</li>
                </ul>
                : null}
            <label htmlFor={"addby"} className={"form__label"}>Add For:</label>
            <select defaultValue={"user"} onChange={(ev) => setAddby(ev.currentTarget.value)} name={"addby"}
                    id={"addby"} className={"form__input"}>
                <option value={"user"}>Individual User</option>
                <option value={"poe"}>Property Ownership Entity</option>
            </select>

            {addby === "user" ? (<>
                <label htmlFor="user_id" className="form__label">Investor Email</label>
                <select name="user_id" id="user_id" className="form__input" required>
                    <option value="" className="form__input">Select User...</option>
                    {users.map(user => {
                        return (<option className="form__input" value={user.id}>{user.email}</option>);
                    })}
                </select>
            </>) : null}

            {addby === "poe" ? (<>
                <label htmlFor="poe" className="form__label">Property Ownership Entity</label>
                <select name="poe" id="poe" className="form__input" required>
                    <option value="" className="form__input">Select POE...</option>
                    {poes.map(poe => {
                        return (<option className="form__input" value={poe.id}>{poe.name}</option>);
                    })}
                </select>
            </>) : null}

            <label htmlFor="property_id" className="form__label">Property</label>
            <select name="property_id" id="property_id" className="form__input" required>
                <option value="" className="form__input">Select Property...</option>
                {properties.map(property => {
                    return (<option className="form__input"
                                    value={property.id}>{property.property_name}</option>);
                })}
            </select>

            <label htmlFor="is_forecast" className="form__label">Is Forecasted Distribution</label>
            <select name="is_forecast" id="is_forecast" className="form__input" required>
                <option value="false" className="form__input">No</option>
                <option value="true" className="form__input">Yes</option>
            </select>

            <label htmlFor="date" className="form__label">Date of Distribution</label>
            <input type="date" name="date" id="date" className="form__input" required/>

            <label htmlFor="value" className="form__label">Distribution Amount ($)</label>
            <input type="number" name="value" id="value" step="0.01" className="form__input" required/>

            <div className="form__row form__row--justify-center">
                <div className="form__column form__column--center">
                    <button onClick={submit} type="button" className="button button--blue-opaque">Submit</button>
                    <a href="/admin/distributions" className="paragraph button button--blue">Go
                        Back</a>
                </div>
            </div>
        </form>
    );
};

export default Create;

const init = () => {
    if (document.querySelector('[data-react-component="Create-Distributions"]') && createDistData) {
        ReactDOM.render(
            <Create {...createDistData} />, document.querySelector('[data-react-component="Create-Distributions"]'));
    }
}
init();

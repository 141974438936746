
const TableCell = props => {
    const {data, type, ...rest} = props;
    let output = data;
    let classes = [];
    if (typeof data === "boolean" && type == "isActive") {
        if (data) {
            output = "Active";
            classes.push('green');
        } else {
            output = "Inactive";
            classes.push('red');
        }
    }
    return (
        <span className={classes} {...rest}>
            {output}
        </span>
    );
};

export default TableCell;

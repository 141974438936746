export const useUpdateData = ({endpoint, setLoading, setData, setErrors}) => {
    const updateData = async () => {
        const request = new Request(endpoint, {
            method: "GET",
            cache: "default"
        });
        const response = await fetch(request);
        const data = await response.json();

        setLoading(false);
        if (data?.error) {
            setErrors([data?.message, ...data?.error_data]);
            return;
        }
        setData(data);
        setErrors([]);
    };
    return {updateData};
};

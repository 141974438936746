import React from 'react';

const ViewDocument = props => {
    const {data, children, ...rest} = props;
    return (
        <a {...rest} target={"_blank"} href={data?.document_filepath} className={"form__icon form__icon--button form__icon--button-edit"}>
            {children}
        </a>
    );
};

export default ViewDocument;

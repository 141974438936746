export const useDate = () => {
    const filterDataDate = ({start, end, data}) => {
        if (!data) {
            return data;
        }
        return data.filter(dataRow => {
            if (!dataRow?.date) {
                return true;
            }
            const dataDate = new Date(dataRow.date);
            return dataDate < end && dataDate > start
        });
    };

    return {
        filterDataDate
    };
};

import React, {useState} from 'react';
import {array, number} from "prop-types";

const DynamicMultiRow = props => {
    const {initRowCount = 0, poes, ...rest} = props;
    const [rowCount, setRowCount] = useState(poes?.length ? poes.length : initRowCount);
    const rows = Array.from(Array(rowCount)).map((x,i) => {
        let nameVal = '';
        if (poes?.[i]?.name) {
            nameVal = poes[i].name;
        }
        return (
            <div {...rest} key={'dynamic_' + i} className={"form__column form__column--flex-grow"}>
                <label htmlFor={'dynamic_' + i}>POE name</label>
                <input className={"form__input"} defaultValue={nameVal} name={'poe_name['+i+']'}/>
                {/*<label htmlFor={'dynamic_' + i}>Stake %</label> todo add back*/}
                {/*<input className={"form__input"} name={'stake['+i+']'}/>*/}
            </div>
        );
    });
    return (
        <div className={"dynamic-row"}>
            {rows}
            <button type={"button"} onClick={() => {setRowCount((prevRowCount) => ++prevRowCount)}}>Add Row</button>
            <button type={"button"} onClick={() => {setRowCount((prevRowCount) => {
                if (prevRowCount < 1) {
                    return 0;
                }
                return --prevRowCount;
            })}}>Remove Row</button>
        </div>
    );
};

DynamicMultiRow.propTypes = {
    initRowCount: number,
    poes: array
};

export default DynamicMultiRow;

import React from 'react';
import {arrayOf, func, number} from "prop-types";

/**
 * Use the callback to push our ID to a number list State
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const AddPoeButton = props => {
    const {callback: setAssocPoes, assocPoes, data, children, ...rest} = props;
    return (
        <input {...rest} defaultChecked={assocPoes?.some(assocPoe => assocPoe === data.id)} name={"assoc_poe[" + data.id + "]"} type={"checkbox"} onInput={(ev) => setAssocPoes((oldIds) => {
            if (!ev.target.checked) {
                return oldIds.filter(oldId => oldId !== data.id)
            }
            return [...oldIds, data.id]
        })}/>
    );
};

AddPoeButton.propTypes = {
    callback: func,
    assocPoes: arrayOf(number)
}

export default AddPoeButton;

import React, {useEffect, useState} from 'react';
import ReactDOM from "react-dom";
import Table from "../Table";
import {useSearch} from "../../hooks/useSearch";
import {useUpdateData} from "../../hooks/useUpdateData";
import {usePager} from "../../hooks/usePager";
import PrintButton from "../PrintButton";
import SearchBox from "../SearchBox";
import Error from "../Error";
import {useDate} from "../../hooks/useDate";

const InvestorDistributions = props => {
    const {tableData, tableHeaders, ...rest} = props;
    const [errors, setErrors] = useState([]);
    const [isLoading, setLoading] = useState(!tableData);
    const [data, setData] = useState(tableData);
    const [headers, setHeaders] = useState(tableHeaders);
    const [searchValue, setSearchValue] = useState(null);
    const [limit, setLimit] = useState(15);

    const [currentDateRange, setDateRange] = useState('all');
    const [currentCustomStartDate, setCustomStartDate] = useState(new Date());
    const [currentCustomEndDate, setCustomEndDate] = useState(new Date());
    const [customEnableState, setCustomEnableState] = useState(false);

    const handleDateFilterChange = event => {
        let dateFilter = event.target.value;
        setCustomEnableState(dateFilter === 'custom');
        setDateRange(dateFilter);
    }

    const {filterData} = useSearch();
    const {filterDataDate} = useDate();
    const {updateData} = useUpdateData({
        endpoint: "/investor/distributions/json",
        setLoading,
        setData,
        setErrors
    });
    const {curPage, maxPage, prevPage,nextPage, startNum, endNum} = usePager({limit, dataLength: data.length});

    useEffect(() => {
        setData(filterData({searchValue, data: tableData}));
    }, [searchValue]);
    useEffect(() => {
        let start = new Date();
        let end = new Date();
        if (currentDateRange === 'quarter') {
            start.setMonth(start.getMonth() - 3);
        }
        if (currentDateRange === 'ytd') {
            start.setMonth(0);
        }
        if (currentDateRange === 'all') {
            start.setFullYear(1969);
            end.setFullYear(2199);
        }
        if (currentDateRange === 'custom') {
            start = new Date(currentCustomStartDate);
            end = new Date(currentCustomEndDate);
        }
        setData(filterDataDate({start, end, data: tableData}))
    }, [currentDateRange, currentCustomStartDate, currentCustomEndDate]);

    useEffect(async () => {
        if (!data) {
            await updateData();
        }
    }, []);

    return (
        <>
            <Error errors={errors}/>
            <div className={"form__filter form__filter--active"}>
                <div className={"form__row form__row--align-flexend"}>
                    <div className={"form__column"}>
                        <label className="form__label">Timeframe:</label>
                        <select name="date" id="date" data-filter={"timeframe-range"} className={"form__input--filters"} onChange={handleDateFilterChange}>
                            <option value="all" data-filter-option={"all"}>All</option>
                            <option value="ytd" data-filter-option={"ytd"}>Year-to-Date</option>
                            <option value="quarter" data-filter-option={"quarter"}>Current Quarter</option>
                            <option value="custom" data-filter-option={"custom"}>Custom</option>
                        </select>
                    </div>
                </div>
            </div>


            <div className={"form__filter" + (customEnableState ? " form__filter--active" : "")} id={"custom-date-range"}>
                <div className={"form__column"}>
                    <label className="form__label">Custom Dates: </label>
                    <div className={"form__row form__row--align-center"}>
                        <input type="date" id="start" name="start_date" className={"form__input--filters"}
                               defaultValue={currentCustomStartDate} max={new Date()} onChange={(ev) => {setCustomStartDate(ev.target.value)}}/>
                        <label className={"form__label--large"}>to</label>
                        <input type="date" id="end" name="end_date" className={"form__input--filters"}
                               defaultValue={currentCustomEndDate} max={new Date()} onChange={(ev) => {setCustomEndDate(ev.target.value)}}/>
                    </div>
                </div>
            </div>

            <span>
                <SearchBox className={"searchBox form--inline"} searchValue={searchValue} setSearchValue={setSearchValue}/>
                <span>
                    <label htmlFor={'perPage'}>Show per page:</label>
                    <select name={'perPage'} className={'form__perpage'} onChange={(ev) => {setLimit(parseInt(ev.target.value) ? parseInt(ev.target.value) : 15)}}>
                        <option value={"15"}>15</option>
                        <option value={"25"}>25</option>
                        <option value={"50"}>50</option>
                        <option value={"10000"}>MAX</option>
                    </select>
                </span>
            </span>

            <Table
                className={"table--wrap"}
                name={"distributions"}
                data={data}
                headers={headers}
                curPage={curPage}
                startNum={startNum}
                endNum={endNum}
                />

            <span>
                <button className={"button--blue-opaque"} onClick={prevPage}>&lt;</button>
                <span className={"table__page"}>{curPage + 1} / {maxPage + 1}</span>
                <button className={"button--blue-opaque"} onClick={nextPage}>&gt;</button>
            </span>
        </>

        );
};

if (document.querySelector('[data-react-component="Investor-Distributions"]')) {
    ReactDOM.render(<InvestorDistributions {...tableReactData} />, document.querySelector('[data-react-component="Investor-Distributions"]'));
}

export default InvestorDistributions;

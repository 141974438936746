export const useMultiDelete = ({endpoint, csrfToken, deleteIds, setLoading, setData, setErrors, setDeleteIds}) => {
    const deleteFn = async () => {
        setLoading(true);
        if (!csrfToken || !csrfToken.length) {
            console.error('no csrf token');
            return;
        }

        const formData = new FormData();
        formData.set("_token", csrfToken);
        const headers = new Headers({
            "X-Requested-With": "XMLHttpRequest",
            "X-CSRF-TOKEN": csrfToken,
            "Content-Type": 'application/json'
        });
        const request = new Request(endpoint, {
            method: "DELETE",
            headers: headers,
            cache: "default",
            body: deleteIds
        });
        const response = await fetch(request);
        const data = await response.json();

        setLoading(false);
        if (data?.error) {
            setErrors([data?.message, ...data?.error_data]);
            return;
        }
        setDeleteIds([]);
        setData(data);
        setErrors([]);
    };

    return {
        deleteFn
    };
};
